/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import Link from 'next/link';
import config from '@config';

const ViewTop = (props) => {
    const {
        isLogin,
        t,
        companyStatus,
        data,
        // handleLogout,
    } = props;
    const { modules } = config;
    const customerCode = data?.customer?.cust_code;
    return (
        <ul>
            {
                (isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                    <li>
                        <Link href="/catalog/product_compare">
                            <a>
                                <img src="/assets/img/iconcompare.svg" alt="compare" />
                                <span>Bandingkan Product</span>
                            </a>
                        </Link>
                    </li>
                )
            }
            {
                (isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                    <li>
                        <Link href="/sales/order/quick">
                            <a>
                                <img src="/assets/img/iconquick.svg" alt="quick" />
                                <span>Quick Order</span>
                            </a>
                        </Link>
                    </li>
                )
            }
            {modules.trackingorder.enabled ? (
                <li>
                    <Link href="/sales/order/track">
                        <a>
                            <img src="/assets/img/icontracking.svg" alt="tracking" />
                            <span>
                                {t('common:menu:trackingorder')}
                            </span>
                        </a>
                    </Link>
                </li>
            ) : null}

            <style jsx>
                {`
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;
                        float: right;
                        font-size: 10px;
                        text-transform: capitalize;
                        font-family: Montserrat !important;
                    }

                    li {
                        display: inline-block;
                        padding: 0 10px;
                        position: relative;
                    }
                    li:hover > ul {
                        display: block;
                    }
                    ul ul {
                        position: absolute;
                        display: none;
                        margin: 0;
                        padding: 5px 10px;
                        z-index: 999;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                    }
                    ul ul li {
                        display: block;
                    }

                    ul ul ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                    }
                    a {
                        color: #fff;
                        text-decoration: none;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }

                    a img {
                        margin-right: 6px;
                    }

                    a:hover {
                        color: #b9acac;
                    }
                `}
            </style>
        </ul>
    );
};
export default ViewTop;
