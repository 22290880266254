/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import makeStyles from '@material-ui/core/styles/makeStyles';
import { GREEN_THEME } from '@root/src/theme/colors';

const useStyles = makeStyles({
    root: {
        // margin: 20,
        marginLeft: '34px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    cartIcon: {
        width: '40px',
        height: '40px',
        objectFit: 'cover',
    },
    cartIconMobile: {
        width: '40px',
        height: '40px',
        objectFit: 'cover',
        marginRight: '10px',
    },
    cartAmount: {
        padding: '9px 12px',
        background: GREEN_THEME,
        borderRadius: '4px',
        color: '#fff',
        marginLeft: '5px',
        marginRight: '11px',
    },
    cartText: {
        fontSize: '14px',
        textTransform: 'capitalize',
        margin: 0,
    },
});

const WithLink = ({ cartData, handleLink, isHomepage = false }) => {
    const styles = useStyles();
    return (
        <div className={styles.root} onClick={handleLink}>
            <img
                className={isHomepage ? styles.cartIcon : styles.cartIconMobile}
                src="/assets/img/iconcart.svg"
                alt="cart"
            />
            {
                isHomepage && (
                    <>
                        <div className={styles.cartAmount}>
                            {cartData || 0}
                        </div>
                        <p className={styles.cartText}>Keranjang</p>
                    </>
                )
            }
        </div>
    );
};

export default WithLink;
