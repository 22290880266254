/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
// import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
// import NotificationBell from '@plugin_notificationbell';
import ShoppingBagIcon from '@plugin_shoppingbag';
// import ProductCompareIcon from '@core_modules/catalog/plugins/ProductCompare';
import IconButton from '@material-ui/core/IconButton';
import Link from 'next/link';
// import DesktopInstallApp from '@core_modules/theme/components/custom-install-popup/desktop';
import Menu from '@core_modules/theme/components/header/desktop/components/mcategory';
import TopMenu from '@core_modules/theme/components/header/desktop/components/mtop';
import Autocomplete from '@core_modules/theme/components/header/desktop/components/autocomplete';
import OptionAutocomplete from '@core_modules/theme/components/header/desktop/components/autocomplete/view';
import { GREEN_THEME } from '@root/src/theme/colors';
import PersonButton from './PersonButton';

const ViewTopNavigation = (props) => {
    const {
        storeConfig,
        handleSearch,
        searchByClick,
        setValue,
        value,
        data,
        loading,
        t,
        isLogin,
        customer,
        handleLogout,
        app_cookies,
        showGlobalPromo,
        // modules,
        vesMenuConfig,
        // appName = 'Swift PWA',
        // installMessage = 'Install',
        // enablePopupInstallation = false,
        companyStatus = '',
    } = props;

    const [triger, setTriger] = React.useState(false);

    const maxHeigtToShow = 600;

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            const middleSubmenu = document.getElementById('submenu-center');
            const leftSubmenu = document.getElementById('submenu-left');
            const checkScrollTop = () => {
                // handle show hide header
                if (middleSubmenu && leftSubmenu) {
                    if (window.pageYOffset > 100) {
                        middleSubmenu.classList.remove('hidden-submenu');
                        leftSubmenu.classList.add('hidden-submenu');
                    } else {
                        middleSubmenu.classList.add('hidden-submenu');
                        leftSubmenu.classList.remove('hidden-submenu');
                    }
                }
                if (!triger && window.pageYOffset > maxHeigtToShow) {
                    setTriger(true);
                } else if (triger && window.pageYOffset < maxHeigtToShow) {
                    setTriger(false);
                }
            };
            window.addEventListener('scroll', checkScrollTop);
        }
    }, [triger]);

    return (
        <div id="header">
            <div className="row header-top">
                <main className="header-top-wrapper">
                    {/* {enablePopupInstallation ? <DesktopInstallApp appName={appName} installMessage={installMessage} /> : null} */}
                    <a className="hotline-wrapper" href="#">
                        <img src="/assets/img/iconphone.svg" alt="hotline" />
                        <span className="hotline">HOTLINE</span>
                        <span className="phonenumber">012 3456 7890</span>
                    </a>
                    <TopMenu
                        companyStatus={companyStatus}
                        t={t}
                        isLogin={isLogin}
                        data={customer}
                        handleLogout={handleLogout}
                        app_cookies={app_cookies}
                    />
                </main>
            </div>
            <main style={{ width: '100%', maxWidth: 'unset' }}>
                <div className="header-main">
                    <div
                        className="header-middle"
                        style={{
                        paddingLeft: '36px',
                        paddingRight: '36px',
                        paddingBottom: '10px',
                        }}
                    >
                        <div className="header-middle__left">
                            <div className="box header-middle__logo">
                                <Link href="/">
                                    <img
                                        className="header-middle__logo-link"
                                        src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                    />
                                </Link>
                            </div>
                        </div>
                        {/* <div id="submenu-center" className="header-middle__center hidden-submenu">
                            <div className="row menu-category">
                                <div className="col-xs-12 menu-middle">
                                    {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                                </div>
                            </div>
                            <div className="header-small__menu">
                                {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                            </div>
                        </div> */}
                        <div className="header-middle__search">
                            {/* <TextField
                                id="standard-basic"
                                label="Search..."
                                onChange={(e) => setValue(e.target.value)}
                                onKeyPress={(e) => handleSearch(e)}
                            /> */}
                            <Autocomplete width="100%" setValue={setValue} handleSearch={handleSearch} OptionsItem={OptionAutocomplete} t={t} />
                            <div className="search-icon">
                                <IconButton disabled={value === ''} edge="start" onClick={searchByClick} aria-label="close">
                                    <SearchIcon fontSize="small" style={{ color: GREEN_THEME }} />
                                </IconButton>
                            </div>
                        </div>
                        <div className="header-middle__right">
                            <div className="box">
                                <div className="header-middle__icons rightHeaderContent">
                                    <div aria-controls="simple-menu" className="header-middle-account">
                                        {
                                            isLogin && isLogin === 1 ? (
                                                <PersonButton customer={customer} handleLogout={handleLogout} />
                                            ) : (
                                                <div>
                                                    <Link href="/customer/account/login">
                                                        <a className="register-button">
                                                            Masuk
                                                        </a>
                                                    </Link>
                                                    <Link href="/customer/account/create">
                                                        <a className="register-button">
                                                            Daftar
                                                        </a>
                                                    </Link>
                                                </div>
                                            )
                                        }

                                    </div>
                                    {/* <div className="notification">
                                        <NotificationBell withLink />
                                    </div> */}
                                    {/* {modules.productcompare.enabled && (
                                        <div className="shopping-bag">
                                            <ProductCompareIcon withLink isLogin={isLogin} />
                                        </div>
                                    )} */}
                                    <div className="shopping-bag">
                                        <ShoppingBagIcon withLink isHomepage storeConfig={storeConfig} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            justifyContent: 'center',
                            width: '100%',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            paddingTop: '0px',
                            }}
                        className="header-middle"
                    >
                        <div id="submenu-left" className="header-middle__center" style={{ width: '100%' }}>
                            <div className="row menu-category" style={{ marginLeft: '0px', marginRight: '0px' }}>
                                <div className="col-xs-12 menu-middle" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                    {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                                </div>
                            </div>
                            <div className="header-small__menu">
                                {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <div className="header-tab">
                <div className="row menu-category" style={{ marginLeft: '0px', marginRight: '0px' }}>
                    <div style={{ marginLeft: '0px', marginRight: '0px' }} className="col-xs-12">{loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}</div>
                </div>
                <div className="header-small__menu">
                    {loading ? null : <Menu vesMenuConfig={vesMenuConfig} data={data} storeConfig={storeConfig} />}
                </div>
            </div>
            <style jsx>
                {`
                    .header-main {
                        max-width: 100%;
                        width: 100%;
                        margin: 0 auto;
                        background: #fff;
                        padding-left: 0px;
                        padding-right: 0px;
                        box-shadow: 0px 0px 6px #00000040;
                    }
                    .header-top-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding-left: 36px;
                        padding-right: 36px;
                    }
                    .hotline-wrapper {
                        display: flex;
                        align-items: center;
                    }
                    .hotline {
                        font-weight: bold;
                        font-size: 12px;
                        margin-right: 6px;
                        margin-left: 6px;
                    }
                    .phonenumber {
                        font-size: 16px;
                        font-weight: bold;
                    }
                    @media (min-width: 768px) {
                        #header {
                            ${storeConfig && storeConfig.pwa && storeConfig.pwa.enabler_sticky_header
                                ? 'position: fixed;'
                                : 'position: relative; z-index: 1100;'}
                            width: 100%;
                            background: white;
                            z-index: 3;
                            top: ${showGlobalPromo ? '45px' : '0'};
                            transition: top 1s ease;
                            box-shadow: 0px 0px 6px #00000040;
                        }
                        .header-middle__center {
                            display: none;
                        }
                        .hidden-submenu {
                            display: none !important;
                            transition: display 1s ease;
                        }
                    }
                    main {
                        // background-color: #fff;
                    }
                    .header-top {
                        // height: 45px;
                        border-bottom: 1px solid #d6d6d6;
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        margin: 0;
                        background: ${GREEN_THEME};
                        color: white;
                    }
                    @media only screen and (max-width: 1023px) and (min-width: 768px) {
                        .header-top {
                            height: unset;
                            padding-top: 0;
                        }
                    }
                    .header-middle {
                        padding-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                    .header-middle__left {
                        width: 212px;
                        height: 55px;
                    }
                    .header-middle__right {
                        width: 100%;
                        max-width: 330px;
                    }
                    .header-small__menu {
                        display: none;
                    }
                    .header-middle img {
                        width: 212px;
                        height: 55px;
                    }
                    .header-middle__logo-link {
                        cursor: pointer;
                    }
                    .header-middle__icons {
                        float: right;
                    }
                    .header-middle__icons > div {
                        margin-right: 0px;
                        margin-left: 0px;
                        display: inline-block;
                    }
                    .search-icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 9;

                    }
                    .header-middle__search {
                        display: flex;
                        align-items: center;
                        float: right;
                        position: relative;
                        width: 100%;
                        margin-left: 42px;
                        margin-right: 42px;
                    }
                    .menu-category {
                        width: 100%;
                        display: block;
                    }
                    .global-promo {
                        height: 45px;
                        border-bottom: 1px solid #d6d6d6;
                        display: flex;
                        align-items: center;
                        padding: 10px 0;
                        margin: 0;
                        background-color: red;
                    }
                    .rightHeaderContent {
                        display: flex;
                        align-items: center;
                    }
                    .register-button {
                        margin-left: 8px;
                        padding: 9px 11px;
                        color: #000;
                        border: 1px solid #6BC498;
                        border-radius: 4px;
                        font-size: 13px;
                    }
                    .register-button:hover {
                        background: #6BC498;
                        color: #FFFFFF;
                    }
                    .account-wrapper{
                        display: flex;
                        align-items: center;
                        text-transform: capitalize;
                        cursor: pointer
                    }
                    .account-text-wrapper {
                        margin-left: 10px;
                    }
                    .account-welcome {
                        margin: 0;
                        font-size: 12px;
                    }
                    .account-name {
                        color: #6AC498;
                        margin: 0;
                        font-size: 12px;
                    }
                    .account-name span:first-child {
                        margin-right: 7px;
                    }
                    .header-middle-account {
                    }
                    .customer-content-wrapper {
                        box-shadow: 0px 0px 6px #00000029;
                        list-style: none;
                        padding: 9px 14px;
                        position: absolute;
                        left: 0;
                        top: 24px;
                        background: #fff;
                        min-width: 160px;
                        // display: none;
                    }
                    .customer-content-wrapper li {
                        margin-bottom: 4px;
                    }
                    .customer-content-link {
                        font-size: 14px;
                        line-height: 18px;
                    }
                    // .header-middle-account:hover .customer-content-wrapper {
                    //     display: block;
                    // }
                `}
            </style>
            <style global jsx>
                {`
                    .hidden-submenu {
                        display: none !important;
                        transition: display 1s ease;
                    }
                    .header-small {
                        top: -45px !important;
                    }
                    @media (min-width: 1250px) {
                        .header-small {
                            display: block;
                        }
                        .header-middle__center {
                            display: block !important;
                        }
                        .header-tab {
                            display: none;
                        }
                        .header-small .menu-category {
                            display: none;
                        }
                        .header-small .header-small__menu {
                            transition: top 1s ease;
                        }
                        .header-small {
                            height: 75px !important;
                        }
                        .hidden-submenu {
                            display: none !important;
                            transition: display 1s ease;
                        }
                    }
                `}
            </style>
        </div>
    );
};

export default ViewTopNavigation;
