import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    listContainer: {
        height: 75,
        paddingTop: 10,
        width: '100%',
        // borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    firstListContainer: {
        height: 100,
    },
    topTitle: {
        display: 'block',
        width: '100%',
        height: '20px',
        paddingLeft: '13px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        marginBottom: '8px',
    },
    imageContainer: {
        float: 'left',
        marginRight: 20,
    },
    img: {
        width: '50px',
        height: 'auto',
    },
    title: {
        paddingTop: 10,
        paddingBottom: 5,
        textTransform: 'uppercase',
        fontSize: 10,
    },
    price: {
        fontWeight: 'bold',
        fontSize: 10,
    },
    listContainerCategory: {
        width: '100%',
        padding: 10,
        // borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    topTitleCategory: {
        paddingLeft: 0,
    },
    breadcrumbs: {
        paddingBottom: 5,
        textTransform: 'uppercase',
        color: '#929292',
        fontSize: 10,
        fontStyle: 'italic',
    },
    titleCategory: {
        textTransform: 'uppercase',
        fontSize: 10,
    },
    autoCompleteContainer: {
        height: '41px',
    },
    autoCompleteInput: {
        height: '41px',
        '& .MuiInputBase-root': {
            border: '1px solid #989898',
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
            padding: '10px 16px',
        },
        '& .MuiInputBase-input': {
            fontSize: '14px',
            margin: '-8px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
        },

    },
    searchInputRoot: {
        // borderRadius: 666,
        width: '100%',
        fontSize: '12px !important',
        background: '#fff !important',
        borderBottom: '0 solid transparent !important',
        border: '1px solid red',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '0 solid transparent',
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
        },
        // [theme.breakpoints.down(768)]: {
        //     padding: '0 16px 0 32px !important',
        //     '& .MuiOutlinedInput-notchedOutline': {
        //         border: '1px solid #C7C9CB',
        //     },
        //     '&:hover .MuiOutlinedInput-notchedOutline': {
        //         border: '1px solid #C7C9CB',
        //     },
        //     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        //         border: '1px solid #C7C9CB',
        //     },
        // },
        // [theme.breakpoints.up(768)]: {
        //     padding: '0 24px 0 52px !important',
        // },
    },
}));

export default useStyles;
