import Badge from '@material-ui/core/Badge';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
    customBadge: {
        '& .MuiBadge-badge': {
            backgroundColor: '#6BC498',
            color: '#fff',
        },
    },
});

const WithoutLink = ({ cartData = 0 }) => {
    const styles = useStyles();
    return (
        <Badge
            classes={{ root: styles.customBadge }}
            badgeContent={cartData || 0}
            variant="dot"
        >
            <div className="image-icon">
                <img src="/assets/img/mobilecart.svg" alt="cart" />
            </div>
        </Badge>
    );
};

export default WithoutLink;
