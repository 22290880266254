/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useRouter } from 'next/router';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

const useStyles = makeStyles(() => ({
    accountWrapper: {
        minWidth: '160px',
    },
    loginButton: {
        display: 'flex',
        alignItems: 'center',
        padding: 0,
    },
    accountTextWrapper: {
        marginLeft: '10px',
    },
    welcomeText: {
        margin: 0,
        fontSize: '12px',
        textTransform: 'capitalize',
        textAlign: 'left',
    },
    accountName: {
        color: '#6AC498',
        margin: 0,
        fontSize: '12px',
        textTransform: 'capitalize',
        textAlign: 'left',
        '& span:first-child': {
            marginRight: '7px',
        },
    },
    personIcon: {
        height: '32px',
        width: '32px',
    },
    menuItemWrapper: {
        '& .MuiPaper-root': {
            borderRadius: 0,
            boxShadow: '0px 0px 6px #00000029',
            minWidth: '160px',
        },
        '& .MuiList-padding': {
            padding: '11px 14px',
        },
        '& .MuiListItem-root': {
            padding: 0,
            fontSize: '14px',
            lineHeight: '18px',
            marginBottom: '6px',
            '&.MuiListItem-button:hover': {
                background: 'none',
            },
        },
    },
}));

const PersonButton = ({
    // t,
    handleLogout,
    customer,
}) => {
    const styles = useStyles();
    const router = useRouter();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const options = [
        { label: 'Akun Saya', handleClick: () => router.push('/customer/account') },
        { label: 'Pesanan Saya', handleClick: () => router.push('/sales/order/history') },
        { label: 'Quotation', handleClick: () => router.push('/customer/account/quotationlist') },
        { label: 'Kredit Toko', handleClick: () => router.push('/customer/account/storecredit') },
        { label: 'Keluar', handleClick: handleLogout },
    ];

    const handleCLickButton = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickMenuItem = (option) => {
        option.handleClick();
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={styles.accountWrapper}>
            <Button
                className={styles.loginButton}
                onClick={handleCLickButton}
                disableRipple
            >
                <AccountCircleOutlinedIcon className={styles.personIcon} />
                <div className={styles.accountTextWrapper}>
                    <p className={styles.welcomeText}>Selamat Datang</p>
                    <p className={styles.accountName}>
                        <span>
                            {customer?.customer?.firstname}
                            {' '}
                            {customer?.customer?.lastname}
                        </span>
                        <span>
                            {
                                anchorEl ? (
                                    <svg style={{ transform: 'rotate(180deg)' }} id="icon_arrow_down_red" data-name="icon arrow down red" xmlns="http://www.w3.org/2000/svg" width="8.75" height="5.375" viewBox="0 0 8.75 5.375">
                                        <path id="Path_3" data-name="Path 3" d="M108.375,202.375a1,1,0,0,1-.707-.293l-3.375-3.375a1,1,0,1,1,1.414-1.414l2.668,2.668,2.668-2.668a1,1,0,1,1,1.414,1.414l-3.375,3.375A1,1,0,0,1,108.375,202.375Z" transform="translate(-104 -197)" fill="#6bc498" />
                                    </svg>
                                ) : (
                                    <svg id="icon_arrow_down_red" data-name="icon arrow down red" xmlns="http://www.w3.org/2000/svg" width="8.75" height="5.375" viewBox="0 0 8.75 5.375">
                                        <path id="Path_3" data-name="Path 3" d="M108.375,202.375a1,1,0,0,1-.707-.293l-3.375-3.375a1,1,0,1,1,1.414-1.414l2.668,2.668,2.668-2.668a1,1,0,1,1,1.414,1.414l-3.375,3.375A1,1,0,0,1,108.375,202.375Z" transform="translate(-104 -197)" fill="#6bc498" />
                                    </svg>
                                )
                            }
                        </span>
                    </p>
                </div>
            </Button>
            <Menu
                id="person-menu"
                getContentAnchorEl={null}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                onEntering={() => {
                    document.querySelector('body').style.padding = 0;
                    document.querySelector('body').style.overflow = 'auto';
                }}
                className={styles.menuItemWrapper}
            >
                {options.map((option, index) => (
                    <MenuItem
                        key={index}
                        selected={false}
                        onClick={() => handleClickMenuItem(option)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default PersonButton;
