/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { WHITE, PRIMARY } from '@theme_color';
import getPath from '@helper_getpath';
import { setResolver, getResolver } from '@helper_localstorage';
import Link from 'next/link';
import dynamic from 'next/dynamic';

const MenuChildren = dynamic(() => import('@common_headerdesktop/components/mcategoryChildren'), { ssr: false });

const Menu = (props) => {
    const { data, storeConfig } = props;
    const cmsPages = storeConfig && storeConfig.cms_page ? storeConfig.cms_page.split(',') : [];
    let menu = storeConfig.pwa.ves_menu_enable ? data?.vesMenu?.items : data?.categoryList[0].children;
    if (!menu) {
        menu = [];
    }
    const generateLink = (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        if (storeConfig.pwa.ves_menu_enable) {
            if (cat.link_type === 'category_link') {
                return ['/[...slug]', link];
            }
            const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
            if (cms) {
                return ['/[...slug]', link];
            }
            return [link, link];
        }
        return ['/[...slug]', link];
    };
    const handleClick = async (cat) => {
        const link = cat.link ? getPath(cat.link) : `/${cat.url_path}`;
        const urlResolver = getResolver();
        if (storeConfig.pwa.ves_menu_enable) {
            if (cat.link_type === 'category_link') {
                urlResolver[link] = {
                    type: 'CATEGORY',
                    id: cat.category_id,
                };
                await setResolver(urlResolver);
            } else {
                const cms = cmsPages.find((cmsPage) => cmsPage === link.replace('/', ''));
                if (cms) {
                    urlResolver[link] = {
                        type: 'CMS_PAGE',
                    };
                    await setResolver(urlResolver);
                }
            }
        } else {
            urlResolver[link] = {
                type: 'CATEGORY',
                id: cat.id,
            };
            await setResolver(urlResolver);
        }
    };
    return (
        <div className="menu-wrapper" role="navigation">
            <ul className="nav" role="menubar">
                {menu.map((val, idx) => {
                    if ((val.include_in_menu || storeConfig.pwa.ves_menu_enable) && val.name) {
                        return (
                            <div>
                                <li key={idx} role="menuitem">
                                    {
                                        (val.name === 'Beranda')
                                            ? (
                                                <div className="icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="17.625"
                                                        height="16.833"
                                                        viewBox="0 0 17.625 16.833"
                                                    >
                                                        <path
                                                            id="Union_9"
                                                            data-name="Union 9"
                                                            d="M2175.75-166.167a.575.575,0,0,1-.565-.585v-7.415l-1.218,1.275a.551.551,0,0,1-.8,0,
                                                            .6.6,0,0,1,0-.827l7.749-8.111a.555.555,0,0,1,.4-.174h0a.555.555,0,0,1,
                                                            .4.177l7.622,8.112a.6.6,0,0,1-.011.827.555.555,0,0,1-.394.166.553.553,0,0,
                                                            1-.4-.177l-1.064-1.133v7.276a.575.575,0,0,
                                                            1-.565.585Zm.565-1.17h10.021v-7.895l-5.025-5.348-5,5.23Z"
                                                            transform="translate(-2172.438 182.5)"
                                                            stroke="#000"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="1"
                                                        />
                                                    </svg>

                                                </div>
                                            )
                                            : (
                                                <>
                                                    {
                                                        (val.name === 'Kategori Produk')
                                                            ? (
                                                                <div className="icon">
                                                                    <svg
                                                                        id="icon_kategori_produk"
                                                                        data-name="icon kategori produk"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="13"
                                                                        height="13"
                                                                        viewBox="0 0 13 13"
                                                                    >
                                                                        <rect
                                                                            className="kateg"
                                                                            id="Rectangle_1479"
                                                                            data-name="Rectangle 1479"
                                                                            width="6"
                                                                            height="6"
                                                                            rx="1"
                                                                            fill="#000"
                                                                        />
                                                                        <rect
                                                                            className="kateg"
                                                                            id="Rectangle_1482"
                                                                            data-name="Rectangle 1482"
                                                                            width="6"
                                                                            height="6"
                                                                            rx="1"
                                                                            transform="translate(0 7)"
                                                                            fill="#000"
                                                                        />
                                                                        <rect
                                                                            className="kateg"
                                                                            id="Rectangle_1480"
                                                                            data-name="Rectangle 1480"
                                                                            width="6"
                                                                            height="6"
                                                                            rx="1"
                                                                            transform="translate(7)"
                                                                            fill="#000"
                                                                        />
                                                                        <rect
                                                                            className="kateg"
                                                                            id="Rectangle_1481"
                                                                            data-name="Rectangle 1481"
                                                                            width="6"
                                                                            height="6"
                                                                            rx="1"
                                                                            transform="translate(7 7)"
                                                                            fill="#000"
                                                                        />
                                                                    </svg>

                                                                </div>
                                                            )
                                                            : (
                                                                <>
                                                                    {
                                                                        (val.name === 'Penawaran Terbatas')
                                                                            ? (
                                                                                <div className="icon">
                                                                                    <svg
                                                                                        id="icon_percent"
                                                                                        data-name="icon percent"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="19"
                                                                                        height="19"
                                                                                        viewBox="0 0 19 19"
                                                                                    >
                                                                                        <rect
                                                                                            id="Rectangle_1500"
                                                                                            data-name="Rectangle 1500"
                                                                                            width="19"
                                                                                            height="19"
                                                                                            fill="none"
                                                                                        />
                                                                                        <path
                                                                                            id="Line_18"
                                                                                            data-name="Line 18"
                                                                                            d="M-11.109.469a.888.888,0,0,1-.63-.261.891.891,0,0,1,
                                                                                            0-1.26L-1.052-11.739a.891.891,0,0,
                                                                                            1,1.26,0,.891.891,0,0,1,0,
                                                                                            1.26L-10.48.208A.888.888,0,0,1-11.109.469Z"
                                                                                            transform="translate(15.266 15.266)"
                                                                                        />
                                                                                        <path
                                                                                            id="Ellipse_241"
                                                                                            data-name="Ellipse 241"
                                                                                            d="M-9.031-12A2.972,2.972,0,0,1-6.062-9.031,
                                                                                            2.972,2.972,0,0,1-9.031-6.062,2.972,2.972,
                                                                                            0,0,1-12-9.031,2.972,
                                                                                            2.972,0,0,1-9.031-12Zm0,4.156A1.189,
                                                                                            1.189,0,0,0-7.844-9.031a1.189,
                                                                                            1.189,0,0,0-1.187-1.187,1.189,1.189,0,0,
                                                                                            0-1.187,1.188A1.189,1.189,0,0,0-9.031-7.844Z"
                                                                                            transform="translate(14.672 14.672)"
                                                                                        />
                                                                                        <path
                                                                                            id="Ellipse_242"
                                                                                            data-name="Ellipse 242"
                                                                                            d="M-9.031-12A2.972,2.972,0,0,
                                                                                            1-6.062-9.031,2.972,2.972,0,0,
                                                                                            1-9.031-6.062,2.972,2.972,0,0,1-12-9.031,
                                                                                            2.972,2.972,0,0,1-9.031-12Zm0,4.156A1.189,
                                                                                            1.189,0,0,0-7.844-9.031a1.189,
                                                                                            1.189,0,0,0-1.187-1.187,
                                                                                            1.189,1.189,0,0,0-1.187,1.188A1.189,
                                                                                            1.189,0,0,0-9.031-7.844Z"
                                                                                            transform="translate(22.391 22.391)"
                                                                                        />
                                                                                    </svg>

                                                                                </div>
                                                                            ) : null
                                                                    }
                                                                </>
                                                            )
                                                    }
                                                </>
                                            )
                                    }
                                    {val.link ? (
                                        <>
                                            <Link href={generateLink(val)[0]} as={generateLink(val)[1]}>
                                                <a onClick={() => handleClick(val)} dangerouslySetInnerHTML={{ __html: val.name }} />
                                            </Link>
                                            {val.name === 'Kategori Produk' ? (
                                                <div className="icon-arrow"><img src="/assets/img/arrow_down.svg" alt="arrow" /></div>
                                            ) : null}
                                        </>
                                    ) : (
                                        <>
                                            {(val.name === 'Penawaran Terbatas')
                                                ? (<a href="#" dangerouslySetInnerHTML={{ __html: val.name }} className="penawaran" />)
                                                : <a href="#" dangerouslySetInnerHTML={{ __html: val.name }} />}
                                        </>
                                    )}

                                    {val.children.length > 0 ? (
                                        <div className="mega-menu" aria-hidden="true" role="menu">
                                            <MenuChildren data={val.children} handleClick={handleClick} generateLink={generateLink} />
                                        </div>
                                    ) : null}
                                </li>

                            </div>
                        );
                    }
                    return null;
                })}
            </ul>
            <style jsx global>
                {`
                    /* mini reset */
                    .nav {
                        width: 100%;
                    }
                    .nav,
                    .nav a,
                    .nav form,
                    .nav input,
                    .nav div,
                    .nav div li,
                    .nav div ul {
                        border: none;
                        margin: 0;
                        padding: 0;
                    }
                    .nav a {
                        text-decoration: none;
                    }
                    .nav div {
                        list-style: none;
                    }

                    /* menu container */
                    .nav {
                        height: 49px;
                        cursor: default;
                        display: flex;
                        justify-content: center;
                        position: relative;
                        z-index: 500;
                        border-bottom: 1px #00000040;
                        box-shadow: 0px 0px 0px #00000040;
                    }

                    /* menu list */
                    .nav > div {
                        float: left;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .icon {
                        display: inline;
                        vertical-align: middle;
                    }
                    .icon-arrow {
                        display: inline;
                        vertical-align: middle;
                    }
                    .icon-arrow img {
                        width: 10px;
                        height: 10px;
                    }
                    .nav > div > li > div > svg {
                        width: 20px;
                        height: 20px;
                    }

                    /* menu links */
                    .nav > div > li > a {
                        background: unset;
                        color: ${PRIMARY};
                        display: inline;
                        font-weight: bold;
                        line-height: 3;
                        font-size: 16px;
                        padding: 0 1.25em;
                        transition: all 0.3s ease;
                        z-index: 510;
                        position: relative;
                    }
                    .nav > div > li > .penawaran {
                        color: #6BC498;
                    }
                    .nav > div:hover,
                    .nav > div:hover > li > a,
                    .nav > div:hover > li > svg {
                        background: #185A58 0% 0% no-repeat padding-box;
                        color: ${WHITE};
                    }
                    .nav > div:hover > li > div > svg > path {
                        fill: white;
                        background-color: white;
                        stroke: white;
                    }
                    .nav > div:hover > li > div > svg > .kateg {
                        fill: white;
                    }
                    .nav > li:hover > a  + .pointer {
                        visibility: visible;
                    }
                    .pointer {
                        visibility: hidden;
                        margin: auto;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 7.5px 13.0px 7.5px;
                        border-color: transparent transparent #212426 transparent;
                    }
                    .nav > li:first-child > a {
                        border-left: none;
                        border-radius: 3px 0 0 3px;
                    }

                    /* search form */
                    .nav-search > form {
                        border-left: 1px solid #4b4441;
                        height: 3.5em;
                        position: relative;
                        width: inherit;
                        z-index: 510;
                    }
                    .nav-search input[type='text'] {
                        background: #372f2b;
                        color: #999;
                        display: block;
                        float: left;
                        font-weight: bold;
                        line-height: 1.5;
                        padding: 1em 0;
                        text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
                        transition: all 0.3s ease 1s;
                        width: 0;
                    }
                    .nav-search input[type='text']:focus {
                        color: #fcfcfc;
                    }
                    .nav-search input[type='text']:focus,
                    .nav-search:hover input[type='text'] {
                        padding: 1em 1.25em;
                        transition: all 0.3s ease 0.1s;
                        width: 6.875em;
                    }
                    .nav-search input[type='submit'] {
                        background: #372f2b url(../img/search-icon.png) no-repeat center center; /* IE8 fallback */
                        background: #372f2b url(../img/search-icon.svg) no-repeat center center;
                        border-radius: 0 3px 3px 0;
                        cursor: pointer;
                        display: block;
                        float: left;
                        height: 3.5em;
                        padding: 0 1.25em;
                        transition: all 0.3s ease;
                    }
                    .nav-search input:focus,
                    .nav-search input[type='submit']:hover {
                        background-color: #4b4441;
                    }

                    }
                    /* menu dropdown */
                    .mega-menu {
                        background: #fff;
                        border: 1px solid #ddd;
                        border-radius: 0 0 3px 3px;
                        opacity: 0;
                        position: absolute;
                        transition: all 0s ease 0s;
                        visibility: hidden;
                        width: 100%;
                        left: 0;
                        margin-left: 0px;
                        margin-right: 0px;
                        min-height: 300px;
                        display: flex;
                        flex-direction: row;
                        box-shadow: 0px 0px 6px #00000040;
                    }
                    li:hover > .mega-menu {
                        opacity: 1;
                        overflow: visible;
                        visibility: visible;

                    }

                    @media (max-width: 1249px) {
                        .mega-menu {
                            background: #fff;
                            border: 1px solid #ddd;
                            border-radius: 0 0 3px 3px;
                            opacity: 0;
                            position: absolute;
                            transition: all 0s ease 0s;
                            visibility: hidden;
                            width: 100%;
                            left: 0;
                            padding: auto;
                            margin: auto;
                            min-height: 353px; 
                        }
                        li:hover > .mega-menu {
                            opacity: 1;
                            overflow: visible;
                            visibility: visible;
                        }
                        .nav-column a {
                            color: #000000 !important;
                            display: block;
                            font-weight: bold;
                            line-height: 1.75;
                            margin: 0;
                            padding: 7px;
                        }
                    }

                    /* menu content */
                    .nav-column a {
                        color: #000000 !important;
                        display: block;
                        font-weight: bold;
                        line-height: 1.75;
                        margin: 0;
                        padding: 7px;
                        left: 68px;
                    }
                    .nav-column a:hover {
                        color: #6BC498 !important;
                        background-color: #F1F1F1;
                    }

                    .nav-column .active {
                        color: #6BC498 !important;
                        background-color: #F1F1F1;
                    }
                    .nav-column h3 {
                        color: #372f2b;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 1.15;
                        text-transform: uppercase;
                    }
                    .cat-label-v2 {
                        top: -6px;
                        position: absolute;
                        background: red;
                        z-index: 99;
                        left: 10px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            </style>
        </div>
    );
};

export default Menu;
